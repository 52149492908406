<template>
  <div class="privacy-background">
    <div class="privacy-container">
      <h1 class="privacy-header">
        <img src="@/assets/inava-icon.png" alt="inava-logo">
        <span>Privacy Policy</span>
      </h1>
      <p>This Privacy Policy sets out how SG Analytics collects and uses information from users (“User” or “you”) of the iNava platform and the website that hosts the <router-link :to="{ path:'/' }">https://inava.io/</router-link> domain. SG Analytics is committed to conducting its business in line with best personal data protection practices.  Personal data refers to any information that identifies you or could be used to identify you.</p>

      <h3>Information Gathering and Usage</h3>

      <p>Only your e-mail address, user-name, company name, and encrypted password are kept by SG analytics as part of your operational record for the iNava platform. We do not retain any other personally identifiable information about you. We do automatically receive and record information on our server logs from your browser, including your IP address, cookie information, and the page you request.</p>

      <p>The user information collected by SG Analytics will be used for the following purposes:</p>
      <ul>
        <li>To personalise User experience on the iNava Platform: we may use information individually or aggregated to understand how our Users use the services and content on the iNava Platform.</li>
        <li>To improve the iNava platform: we continually strive to improve the iNava Platform based on the information and feedback we receive from you.</li>
        <li>To improve customer service: your information helps us to respond to your service requests and support needs more effectively.</li>
      </ul>

      <h3>Information Disclosure</h3>

      <p>SG Analytics may disclose information to respond to subpoenas, court orders, or legal process, in accordance with Section 9 (Confidentiality) of the Subscription Services Terms of Use. It may be necessary to share information as required by such subpoenas, court orders, or legal process in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Service, or as otherwise required by law.</p>

      <h3>Cookies; web analytics</h3>

      <p>We may use cookies and other tools allowing us to track and/or analyze user behavior on our websites. Cookies are small information files that are placed on your device when you visit the iNava platform; these cookies are primarily used to record current login information. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>

      <h3>Confidentiality and Security</h3>

      <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorised access of your information and data stored on our Website. We use a secure server hosting location, firewall protection, controlled access and standard secure data storage practices to protect your data. We also limit access to your data to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs.</p>

      <h3>Third party websites</h3>

      <p>The iNava platform may contain links to third party websites. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to the iNava platform is subject to that website’s own terms and policies.</p>

      <h3>Third-party usage tracking analytics</h3>

      <p>In addition to our own usage analytics, we use third-party analytics tools to track user behavior when you visit the iNava Platform. We do this to improve the user experience for the users of the Platform.</p>

      <h3>California Users Rights under CCPA</h3>

      <p>If you are a resident of California, you have the right to request access, modification, and deletion of any personal information we process about you under the California Consumer Privacy Act. Our CCPA obligations cover any California resident’s personal information we process through the use of our website and services. If you uploaded your personal information at the request of a company that we do not own or control, you must contact them if you wish to exercise your rights under the CCPA.</p>

      <p>When you use our website and services, we collect certain categories of personal information from you including identifying contact and geographic information as well as system usage data. We may disclose these categories of personal information for business purposes in order to provide you services.</p>

      <p>You have the right to request a copy of all personal information we process about you over the past 12 months. We will review your request and provide a disclosure which contains the categories and specific pieces of personal information we have collected, the sources of personal information, the business purposes for collecting, and any third parties with whom we shared your personal information. To ensure your privacy and security, we will take reasonable steps to verify your identity before processing your rights request.</p>

      <p>You have the right to request the deletion of your personal information we have processed. We will direct our service providers to delete your personal information once we have verified your request.</p>

      <p>If you choose to exercise any of your data rights, we will not discriminate against you or your use of our services in any of the following ways, including denying goods or services to you, charging different price rates for the services, or providing a different level or quality of services to you.</p>

      <p>We do not sell your personal information that we collect for the use of our services.</p>

      <p>You can send an email to <a href="mailto:teaminava@sganalytics.com"><em>teaminava@sganalytics.com</em></a> to request a disclosure or deletion of your personal information.</p>

      <h3>EU Users Rights under GDPR</h3>

      <p>You may request access to your information to review, modify, or request deletion of any personal information we process about you. We will review your request and will respond in accordance with applicable data protection laws. To protect your privacy and security, we take reasonable steps to verify your identity before processing rights requests.</p>

      <p>If you are a resident of the EEA, you can object to processing of your personal information, ask us to restrict processing of your personal information, or if applicable, request the portability of your personal information. You can send an email to <a href="mailto:teaminava@sganalytics.com"><em>teaminava@sganalytics.com</em></a>  to exercise these rights. If you are a resident in the EEA, you have the legal right to complain to the data protection authorities in the EEA about our collection and use of your personal information.</p>

      <p>You may also have the right to make a GDPR complaint to the relevant Supervisory Authority. A list of Supervisory Authorities is available here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en</a>. If you need further assistance regarding your rights, please contact us using the contact information we have provided, and we will consider your request in accordance with applicable law. In some cases, our ability to uphold these rights for you may depend upon our obligations to process personal information for security, safety, fraud prevention reasons, compliance with regulatory or legal requirements, or because processing is necessary to deliver the services you have requested. Where this is the case, we will inform you of specific details in response to your request.</p>

      <h3>Changes/Updates to Privacy Policy</h3>

      <p>Your use of the iNava platform is subject to this privacy policy. In order to keep up with changing legislation and best practice, we may revise this privacy policy at any time. When we do, we will post a notification on the main page of our Website. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

      <h3>Contact us</h3>

      <p>If you have any questions about this Privacy Policy, or the practices of the iNava platform, please contact us at <a href="mailto:teaminava@sganalytics.com"><em>teaminava@sganalytics.com</em></a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'

}
</script>

<style lang="scss" scoped>

.privacy-background {
  height: 100vh;
  width: 100%;
  background: #535eeb url('../../assets/login-container-sm.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
    overflow: auto;
  .privacy-container {
    p {
      margin: revert;
    }
    h3 {
      font-size: revert;
      font-weight: revert;
      margin: revert;
    }
    text-align: justify;
    .privacy-header {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      margin: revert;
      span {
        display: block;
      }
      img {
        width: 50px;
      }
      p {
        margin-top: -5px;
      }
    }
    background: white;
    padding: 30px 50px;
    margin: 50px;
    border-radius: 10px;
    ul {
      margin: revert;
      padding: revert;
      margin-right: 35px;
      li {
        list-style-type: disc;
      }
    }
  }
}
</style>
